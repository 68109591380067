/* eslint-disable react/no-array-index-key */
/* eslint-disable no-constant-condition */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-danger */
import React from "react"
import styled from "styled-components"
import Helmet from "react-helmet"
import tw from "tailwind.macro"
import { graphql } from "gatsby"
import { DiscussionEmbed } from "disqus-react"
import renderMarkdown from "../helpers/renderMarkdown"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PrevNext from "../components/PrevNext"
import AppWrapper from "../views/AppWrapper"
import { ContentFull } from "../elements/Content"
import Hero from "../views/Hero"
import MarkdownContainer from "../components/MarkdownContainer"
import { H1Light, Strong, PLight } from "../elements/Text"

const PageTitle = styled(H1Light)`
  ${tw`mb-0`};
`

const PageSubtitle = styled(PLight)`
  ${tw`mt-0`};
`

const Section = ({ children, ...props }) => {
  const { style } = props

  return (
    <ContentFull style={style}>
      <MarkdownContainer>{children}</MarkdownContainer>
    </ContentFull>
  )
}

export default function Template({ pageContext: { prev, next }, data }) {
  const { markdownRemark } = data
  const { frontmatter, htmlAst, fields } = markdownRemark
  const seoDescription = frontmatter.short_description || frontmatter.title

  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME || "featurist-blog",
    config: { identifier: fields.slug, title: frontmatter.title },
  }

  return (
    <Layout>
      <SEO
        title={frontmatter.title}
        keywords={frontmatter.keywords || []}
        description={seoDescription}
      />
      <AppWrapper>
        <Hero isDark isBlog style={{ zIndex: 20 }}>
          <PageTitle>{frontmatter.title}</PageTitle>
          <PageSubtitle>
            By <Strong>{frontmatter.author}</Strong> on{" "}
            <Strong>{frontmatter.date}</Strong> • {fields.readingTime.text}
          </PageSubtitle>
        </Hero>
        <Section condensed>{renderMarkdown(htmlAst)}</Section>
        <Section style={tw`bg-grey-lightest px-0`} condensed>
          <DiscussionEmbed {...disqusConfig} />
        </Section>
        <Section style={tw`bg-grey-light px-0`}>
          <PrevNext prev={prev} next={next} />
        </Section>
      </AppWrapper>
      <Helmet>
        <script async src="https://platform.twitter.com/widgets.js" />
      </Helmet>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      htmlAst
      tableOfContents
      fields {
        slug
        readingTime {
          text
        }
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        subtitles
        author
        short_description
        hidden
      }
    }
  }
`
